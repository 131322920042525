<template>
  <div
    class="AppPanel relative overflow-hidden rounded-md shadow"
    :class="dynamicClasses"
  >
    <div v-if="notifications > 1" class="notification">{{ notifications }}</div>
    <sl-details
      v-if="collapsible"
      v-bind="{ ...$props, ...$attrs }"
      :summary="label"
      class="collapsible"
      v-on="$listeners"
    >
      <template v-if="$slots.warning">
        <div class="bg-salmon p-2 text-center text-sm text-white">
          <slot name="warning"></slot>
        </div>
      </template>
      <div class="space-y-4 bg-gray-light p-4">
        <slot></slot>
      </div>
      <template v-if="$slots.footnote">
        <div class="bg-gray-light p-2 text-center text-sm">
          <slot name="footnote"></slot>
        </div>
      </template>
    </sl-details>
    <template v-else-if="label">
      <a
        v-if="externalLink && trigger"
        :href="externalLink"
        class="pointer absolute left-0 top-0 z-10 flex w-full items-center justify-between border-b border-gray px-6 py-4 text-lg font-bold leading-tight text-navy-dark hover:bg-gray-light"
        target="_blank"
        @click="trackEvent(trigger)"
      >
        {{ label }}
        <BzlIcon
          :custom-icon-name="icon.name"
          :src="icon.src"
        ></BzlIcon>
      </a>
      <a
        v-else-if="externalLink && mobileNewText"
        :href="externalLink"
        class="pointer absolute left-0 top-0 z-10 flex w-full items-center justify-between border-b border-gray px-6 py-4 text-lg font-bold leading-tight text-navy-dark hover:bg-gray-light"
        target="_blank"
      >
        <div class="mobile-div">{{ label }} <span class="new-text">NEW</span></div>
        <BzlIcon
          :custom-icon-name="icon.name"
          :src="icon.src"
        ></BzlIcon>
      </a>
      <a
        v-else-if="externalLink"
        :href="externalLink"
        class="pointer absolute left-0 top-0 z-10 flex w-full items-center justify-between border-b border-gray px-6 py-4 text-lg font-bold leading-tight text-navy-dark hover:bg-gray-light"
        target="_blank"
      >
        {{ label }}
        <BzlIcon
          :custom-icon-name="icon.name"
          :src="icon.src"
        ></BzlIcon>
      </a>
      <p
        v-else-if="noLinkborder"
        class="pointer absolute left-0 top-0 z-10 flex w-full items-center justify-between border-b border-gray px-6 py-4 text-lg font-bold leading-tight text-navy-dark"
        target="_blank"
      >
        {{ label }}
      </p>
      <RouterLink
        v-else-if="routeName && trigger"
        class="absolute left-0 top-0 z-10 flex w-full items-center justify-between border-b border-gray px-6 py-4 text-lg font-bold leading-tight text-navy-dark hover:bg-gray-light"
        :to="{ path: routeName }"
        @click="trackEvent(trigger)"
      >
        {{ label }}
        <BzlIcon
          :custom-icon-name="icon.name"
          :src="icon.src"
        ></BzlIcon>
      </RouterLink>
      <RouterLink
        v-else-if="routeName"
        class="absolute left-0 top-0 z-10 flex w-full items-center justify-between border-b border-gray px-6 py-4 text-lg font-bold leading-tight text-navy-dark hover:bg-gray-light"
        :to="{ path: routeName }"
      >
        {{ label }}
        <BzlIcon
          :custom-icon-name="icon.name"
          :src="icon.src"
        ></BzlIcon>
      </RouterLink>
      <h1
        v-else
        class="absolute left-0 top-0 z-10 w-full px-6 py-4 text-lg font-bold leading-tight text-navy-dark lg:pt-7"
        :class="headerAlignment"
      >
        {{ label }}
      </h1>
      <div
        v-if="!button"
        class="h-full space-y-4"
        :class="contentPadding"
      >
        <slot
          v-bind="$props"
          v-on="$listeners"
        ></slot>
      </div>
    </template>
    <template v-else>
      <slot></slot>
    </template>
  </div>
</template>

<script>
  // https://shoelace.style/components/details
  import '@shoelace-style/shoelace/dist/components/details/details.js'
  import chevronArrow from './chevron-right.svg'

  export default {
    name: 'AppPanel',
    props: {
      label: {
        type: String,
        required: false,
        default: null,
      },
      variant: {
        type: String,
        default: 'primary',
        validator: (val) =>
          [
            'primary', // white
            'alternate', // light gray
            'dark', // blue
            'transparent',
          ].includes(val),
      },
      spacing: {
        type: String,
        default: 'default',
        validator: (val) => ['default', 'none'].includes(val),
      },
      padding: {
        type: String,
        default: 'default',
        validator: (val) => ['default', 'tight', 'none'].includes(val),
      },
      alignment: {
        type: String,
        default: 'left',
        validator: (val) => ['center', 'left', 'right'].includes(val),
      },
      height: {
        type: String,
        required: false,
        default: null,
      },
      routeName: {
        type: String,
        default: '',
        required: false,
      },
      externalLink: {
        type: String,
        default: '',
        required: false,
      },
      noLinkborder: {
        type: Boolean,
        default: false,
        required: false,
      },
      notifications: {
        type: Number,
        default: 0,
        required: false,
      },
      mobileNewText: {
        type: Boolean,
        default: false,
        required: false,
      },
      trigger: {
        type: String,
        default: '',
        required: false,
      },
      button: {
        type: Boolean,
        default: false,
        required: false,
      },
      /* Native props */
      open: {
        type: Boolean,
        required: false,
        default: false,
      },
      collapsible: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    data: () => ({
      icon: {
        name: 'Chevron Arrow',
        src: chevronArrow,
      },
    }),
    computed: {
      dynamicClasses() {
        const classes = []

        if (!this.collapsible) {
          // colors
          classes.push(
            {
              primary: `text-navy-dark bg-white`,
              alternate: `text-navy-dark bg-gray-light`,
              dark: `text-white bg-navy-dark`,
              transparent: `text-navy-dark bg-transparent shadow-none`,
            }[this.variant]
          )

          // spacing
          classes.push(
            {
              default: `space-y-4`,
              none: `space-y-0`,
            }[this.label ? 'none' : this.spacing]
          )

          // padding
          classes.push(
            {
              default: `p-5`,
              tight: `p-4`,
              none: `p-0`,
            }[this.padding]
          )

          // height
          if (this.height) {
            classes.push('h-full')
          }

          if (this.label) {
            classes.push('pt-14')
          }
        }

        return classes.join(' ')
      },

      headerAlignment() {
        const classes = []

        // text-alignment
        classes.push(
          {
            center: `text-center`,
            left: `text-left`,
            right: `text-right`,
          }[this.alignment]
        )

        return classes.join(' ')
      },

      contentPadding() {
        const classes = []

        if (!this.externalLink && !this.routeName) {
          // padding
          classes.push(
            {
              default: `lg:pt-4`,
              tight: `lg:pt-2`,
              none: `lg:pt-0`,
            }[this.padding]
          )

          return classes.join(' ')
        }
      },
    },
  }
</script>

<style>
  .AppPanel .collapsible::part(base) {
    @apply overflow-hidden rounded-lg border-none shadow;
  }

  .AppPanel .label,
  .AppPanel .collapsible::part(header) {
    @apply rounded-none bg-gray-light p-5 text-center text-lg font-bold text-navy-dark;
  }

  .AppPanel .collapsible::part(content) {
    @apply bg-white p-0;
  }

  .AppPanel .collapsible::part(summary-icon) {
    border: 1px solid #0057b8;
    @apply rounded-full bg-white p-0.5;
  }
  .notification {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 55px;
    top: 22px;
    width: 24px;
    height: 24px;
    border-radius: 100%;
    font-weight: 700;
    @apply bg-salmon text-navy-dark;
  }

  .AppPanel hr {
    @apply border-gray;
  }
  .new-text {
    font-weight: 700;
    font-size: 12px;
    line-height: 12px;
    color: #0057b8;
    margin-left: 5px;
  }
  .mobile-div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
