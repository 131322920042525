import gql from 'graphql-tag'
import { DateTime } from 'luxon'

import { howManyDaysFromToday } from '#/mixins'

export default {
  namespaced: true,

  state: {
    allPaymentMethods: [],
  },

  getters: {
    // used by scheduled payments
    getPaymentMethod: (state) => (id) => {
      return state.allPaymentMethods.find((pm) => pm.id === id)
    },
    getExpiringCreditCardNotice({ allPaymentMethods }, getters, rootState, rootGetters) {
      const DAYS_TO_EXPIRE = 30
      let daysLeft = DAYS_TO_EXPIRE + 1 // we only show notice if < 30 days left on any CC
      let cc = null
      let message = null

      // Check payment methods, filter by CC
      allPaymentMethods
        .filter((pm) => pm.paymentType === 'CARD')
        .forEach((pm) => {
          // CCs expire on the first day after the MM/YY printed on it
          const expiredOn = DateTime.fromFormat(pm.expiresOn, 'MM/yy')
            .plus({ months: 1 })
            .toJSDate()

          // we get the CC which has the lowest value of daysLeft
          if (howManyDaysFromToday(expiredOn) < daysLeft) {
            daysLeft = howManyDaysFromToday(expiredOn)
            cc = pm
          }
        })

      if (cc) {
        const isUsedForAutopay = cc.id === rootState.discounts.autopay.paymentMethod?.id
        const isTiedToAScheduledPayment = !!rootGetters['billing/getScheduledPayments'].find(
          (sp) => cc.id === sp.paymentMethodId
        )

        if (daysLeft > 0) {
          // expiring soon
          if (isUsedForAutopay && isTiedToAScheduledPayment) {
            message = `The payment method associated with your future dated payment and auto payment is expiring within the next 30 days.`
          } else if (isUsedForAutopay) {
            message = `The payment method associated with your AutoPay payment is expiring within the next 30 days.`
          } else if (isTiedToAScheduledPayment) {
            message = `The payment method associated with your future dated payment is expiring within the next 30 days.`
          } else {
            message = `One or more of your payment methods will expire within the next 30 days.`
          }
        } else {
          // expired
          if (isUsedForAutopay && isTiedToAScheduledPayment) {
            message = `The Payment method associated with your future dated payment and auto payment has expired.`
          } else if (isUsedForAutopay) {
            message = `The Payment method associated with your autopay payment has expired.`
          } else if (isTiedToAScheduledPayment) {
            message = `The Payment method associated with your future dated payment has expired.`
          } else {
            message = `One or more of your payment methods has expired.`
          }
        }

        message += ` Please update your payment method in the Billing Center.`
      }

      return message
    },
  },

  mutations: {
    SET_PAYMENT_METHODS(state, payload) {
      // Possible bug point: only CARD and EFT are accounted for
      state.allPaymentMethods = payload.map((pm) =>
        Object.assign(
          {
            id: pm.id,
            paymentType: pm.paymentType,
            nickname: pm.nickname,
            isDefault: pm.default,
          },
          {
            CARD: {
              displayName: 'Credit/Debit Card',
              accountHolder: `${pm.cardPaymentInfo.firstName} ${pm.cardPaymentInfo.lastName}`,
              lastFour: pm.cardPaymentInfo.cardNumber,
              expiresOn: `${pm.cardPaymentInfo.expiryMonth}/${pm.cardPaymentInfo.expiryYear}`,
            },
            EFT: {
              displayName:
                pm.eftPaymentInfo.eftAccountType === 'CHECKING'
                  ? 'Checking Account'
                  : 'Savings Account',
              accountHolder: `${pm.eftPaymentInfo.firstName} ${pm.eftPaymentInfo.lastName}`,
              accountNumber: pm.eftPaymentInfo.eftAccountNumber,
              routingNumber: pm.eftPaymentInfo.routingNumber,
              accountType: pm.eftPaymentInfo.eftAccountType,
            },
          }[pm.paymentType]
        )
      )
    },
  },

  actions: {
    initialize({ dispatch }) {
      return Promise.all([dispatch('_getPaymentMethods')])
    },
    _getPaymentMethods({ commit, state, dispatch }) {
      return new Promise((resolve, reject) => {
        window.$apollo.addSmartQuery('getStoredPaymentMethod', {
          query: gql`
            query getStoredPaymentMethod {
              getStoredPaymentMethod {
                cardPaymentInfo {
                  cardNumber
                  expiryMonth
                  expiryYear
                  firstName
                  lastName
                }
                eftPaymentInfo {
                  eftAccountNumber
                  eftAccountType # enum
                  firstName
                  lastName
                  routingNumber
                }
                id
                nickname
                paymentType # enum
                default
              }
            }
          `,
          result: function (r) {
            if (!r.data?.getStoredPaymentMethod) {
              return resolve(null)
            }
          

            //check to see if a card is expired before trying to make it default
            const cardNotExpired = () => {
              const cardMonth = parseInt(r.data?.getStoredPaymentMethod[0]?.cardPaymentInfo?.expiryMonth[0] == '0' ? r.data?.getStoredPaymentMethod[0]?.cardPaymentInfo?.expiryMonth[1] : r.data?.getStoredPaymentMethod[0]?.cardPaymentInfo?.expiryMonth)
              const cardYear = parseInt( r.data?.getStoredPaymentMethod[0]?.cardPaymentInfo?.expiryYear)
              const today = new Date()
              // + 1 because it starts at 0
              const thisMonth = today.getMonth() + 1
              const thisYear = today.getFullYear()
              if(cardYear > thisYear || cardYear == thisYear && cardMonth >= thisMonth  ){
                return true
              }
              return false
            }
            
            const paymentMethods = commit('SET_PAYMENT_METHODS', r.data?.getStoredPaymentMethod)
            if (state.allPaymentMethods.length === 1 && !state.allPaymentMethods[0].isDefault && cardNotExpired()) {
              dispatch('setAsDefault', state.allPaymentMethods[0])
            }
            return r.data?.getStoredPaymentMethod && resolve(paymentMethods)
          },
          error: (e) => reject(e),
        })
      })
    },
    refetchPaymentMethods({ commit }) {
      // needed by AddPaymentMethod (which is not a GQL mutation) to refresh PMs afterwards
      commit('LOCK_APP', null, { root: true })
      return window.$apollo.queries.getStoredPaymentMethod
        .refetch()
        .catch((e) => console.error(e))
        .finally(() => {
          commit('UNLOCK_APP', null, { root: true })
        })
    },
    deletePaymentMethod({ rootState, commit, dispatch }, paymentMethod) {
      commit('LOCK_APP', null, { root: true })

      // delete all associated scheduled payments first
      return dispatch('billing/cancelScheduledPaymentsByPaymentMethod', paymentMethod, {
        root: true,
      })
        .then(() => {
          // then disable Autopay (if this is the payment method being used for it)
          if (paymentMethod.id === rootState.discounts.autopay.paymentMethod?.id) {
            return dispatch('discounts/disableAutopay', null, {
              root: true,
            }).then(() => {
              // have to re-lock the app here because disableAutopay unlocks it
              commit('LOCK_APP', null, { root: true })
            })
          }
        })
        .then(() => {
          return window.$apollo.mutate({
            mutation: gql`
              mutation deleteStoredPaymentMethod($storedPaymentId: String!) {
                deleteStoredPaymentMethod(storedPaymentId: $storedPaymentId) {
                  message
                }
              }
            `,
            variables: {
              storedPaymentId: paymentMethod.id,
            },
          })
        })
        .then(() => {
          // then refresh payment methods and scheduled payments
          return Promise.all([
            window.$apollo.queries.getStoredPaymentMethod.refetch(),
            window.$apollo.queries.getScheduledPayment
              .refetch()
              .then(() => window.$apollo.queries.getRecentPayment.refetch()),
          ])
        })
        .catch((e) => {
          console.error(e)
          return dispatch(
            'alerts/setAlert',
            {
              message: 'Unable to delete payment method. Please contact Support.',
            },
            { root: true }
          )
        })
        .finally(() => {
          commit('UNLOCK_APP', null, { root: true })
        })
    },
    setAsDefault({ commit, dispatch }, paymentMethod) {
      commit('LOCK_APP', null, { root: true })

      return window.$apollo
        .mutate({
          mutation: gql`
            mutation updateStoredPaymentMethod(
              $default: Boolean
              $expiryMonth: String
              $expiryYear: String
              $nickname: String
              $storedPaymentMethodId: String!
            ) {
              updateStoredPaymentMethod(
                default: $default
                expiryMonth: $expiryMonth
                expiryYear: $expiryYear
                nickname: $nickname
                storedPaymentMethodId: $storedPaymentMethodId
              ) {
                id
              }
            }
          `,
          variables: {
            default: true,
            expiryMonth: paymentMethod.expiresOn?.split('/')[0] ?? null,
            expiryYear: paymentMethod.expiresOn?.split('/')[1] ?? null,
            nickname: paymentMethod.nickname,
            storedPaymentMethodId: paymentMethod.id,
          },
        })
        .then(() => {
          return window.$apollo.queries.getStoredPaymentMethod.refetch()
        })
        .catch((e) => {
          console.error(e)
          return dispatch(
            'alerts/setAlert',
            {
              message: 'Unable to delete payment method. Please contact Support.',
            },
            { root: true }
          )
        })
        .finally(() => {
          commit('UNLOCK_APP', null, { root: true })
        })
    },
  },
}
