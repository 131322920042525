<template>
  <AppPanel variant="alternate">
    <div>Modem</div>
    <div
      v-if="allEquipments.length > 0"
      class="grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-6"
    >
      <div
        v-for="eq in allEquipments"
        :key="eq.serialNumber"
        class="border-b border-gray last:border-b-0 lg:border-b-0 lg:border-r last:lg:border-r-0 even:lg:border-r-0"
      >
        <div
          v-if="isCompact"
          class="flex flex-row items-center justify-between"
        >
          <div class="grow border-r border-gray">
            <div class="text-2xl font-bold">Model: {{ eq.model }}</div>
            <span class="font-normal">
              {{ eq.make }}
            </span>
            <div>Serial #: {{ eq.serialNumber }}</div>
          </div>
          <div class="flex grow flex-col items-center">
            <span>
              Status:
              <span class="font-bold">
                <span
                  v-if="getActualStatus(eq) === 'online'"
                  class="text-success"
                >
                  Online
                </span>
                <span
                  v-if="getActualStatus(eq) === 'offline'"
                  class="text-salmon-dark"
                >
                  Offline
                </span>
                <span
                  v-if="getActualStatus(eq) === 'rebooting'"
                  class="animate-pulse text-favorite-dark"
                >
                  Rebooting..
                </span>
              </span>
            </span>
          </div>
        </div>

        <div
          v-else
          class="space-y-4"
        >
          <div class="flex flex-col items-stretch justify-between lg:flex-row lg:items-center">
            <div class="my-4 grow">
              <div class="text-2xl font-bold">Model: {{ eq.make }} {{ eq.model }}</div>
              <span class="font-normal"> Type: {{ eq.type }} </span>
              <div>Serial #: {{ eq.serialNumber }}</div>
              <div class="mt-4">
                <span>
                  Status:
                  <span class="font-bold">
                    <span
                      v-if="getActualStatus(eq) === 'online'"
                      class="text-teal"
                    >
                      Online
                    </span>
                    <span
                      v-if="getActualStatus(eq) === 'offline'"
                      class="text-salmon-dark"
                    >
                      Offline
                    </span>
                    <span
                      v-if="getActualStatus(eq) === 'rebooting'"
                      class="animate-pulse text-favorite-dark"
                    >
                      Rebooting..
                    </span>
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <p
      v-else
      class="text-center"
    >
      No equipment found.
    </p>
  </AppPanel>
</template>

<script>
  export default {
    name: 'EquipmentStatus',
    props: {
      isCompact: {
        type: Boolean,
        default: false,
      },
      allEquipments: {
        type: Array,
        default: () => [],
      },
      rebootList: {
        type: Array,
        default: () => [],
      },
      equipmentTroubleshoot: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      getActualStatus(eq) {
        return this.rebootList.includes(eq.serialNumber)
          ? 'rebooting'
          : eq.status
          ? 'online'
          : 'offline'
      },
    },
  }
</script>
