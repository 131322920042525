export default{
    'AIRQ': {
        name: 'Core',
        downloadSpeed: 100,
        uploadSpeed: 10,
      },
      'AIRR': {
        name: 'Fast',
        downloadSpeed: 200,
        uploadSpeed: 20,
      },
      'AIRS': {
        name: 'SuperFast',
        downloadSpeed: 250,
        uploadSpeed: 20,
      },
      'AIRT': {
        name: 'UltraFast',
        downloadSpeed: 500,
        uploadSpeed: 50,
      },
      'AIRU': {
        name: 'GigaFast',
        downloadSpeed: 1000,
        uploadSpeed: 50,
      },
      'AISQ': {
        name: 'Fiber Core',
        downloadSpeed: 100,
        uploadSpeed: 100,
      },
      'AISR': {
        name: 'Fiber Fast',
        downloadSpeed: 200,
        uploadSpeed: 200,
      },
      'AIST': {
        name: 'Fiber UltraFast',
        downloadSpeed: 500,
        uploadSpeed: 500,
      },
      'AISU': {
        name: 'Fiber GigaFast',
        downloadSpeed: 1000,
        uploadSpeed: 600,
      },
      'AISV': {
        name: 'Fiber GigaFast',
        downloadSpeed: 1000,
        uploadSpeed: 1000,
      },
    }