<template>
  <div class="mt-4 rounded-md bg-gray-light px-6 text-navy-dark shadow-[0_2px_2px_0_rgba(0,0,0,0.2)]">
    <div class="flex cursor-pointer items-center justify-between border-none py-4" @click="toggle">
      <h3 class="text-lg font-bold leading-tight">{{ title }}</h3>
      <span class="pr-1.5">
        <svg v-if="!isOpen" xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
          <circle cx="12" cy="12.5" r="11.5" fill="white" stroke="#0057B8" />
          <path d="M6.80078 10.9004L12.0008 15.7004L17.2008 10.9004" stroke="#004282" stroke-width="2" />
        </svg>
        <svg v-else xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
          <circle cx="12" cy="12" r="11.5" transform="matrix(1 0 0 -1 0 24.5)" fill="white" stroke="#0057B8" />
          <path d="M6.80078 14.0996L12.0008 9.29961L17.2008 14.0996" stroke="#004282" stroke-width="2" />
        </svg>
      </span>
    </div>
    <div v-show="isOpen">
      <p class="mb-6 pt-2">
        Want to stay updated on outages, new products, trends, news, and more? Subscribe to our
        communications by checking the boxes below. Please note that unsubscribing from these
        updates will not affect the receipt of essential service-related messages, such as billing,
        legal, and policy updates.
      </p>
      <div v-if="this.$store.state.userPreferences.updated === true" class="mb-6 flex items-center">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM9.68749 13.645L17.3397 6.00178L19.443 8.10424L9.68749 17.8499L4.55696 12.7215L6.66031 10.6191L9.68749 13.645Z"
            fill="#3D804C" />
        </svg>
        <span class="ml-2 font-semibold">Changes have been saved.</span>
      </div>
      <hr />
      <Web @preference-changed="onPreferenceChange" />
      <Mobile @preference-changed="onPreferenceChange" />
      <hr />
      <div class="flex justify-center py-4">
        <button
          class="cursor-pointer rounded-full border-none bg-blue px-8 py-4 leading-tight text-white hover:opacity-80 disabled:bg-[#9CA5AA] disabled:opacity-50"
          :disabled="!hasChanges" @click="savePreferences()">
          Save
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Mobile from './mobile.vue'
import Web from './web.vue'
import checkIcon from './check-icon.svg'

export default {
  name: 'CommunicationPreferences',
  components: {
    Mobile,
    Web,
  },
  data() {
    return {
      isOpen: false,
      showNotification: false,
      hasChanges: false,
      title: 'Communication Preferences',
    }
  },
  computed: {
    emailOrPhone() {
      return this.$store.state.login.email || ''
    },
    prefsReady() {

      !this.pref.OUTAGE_EMAIL ? false : true
    }
  },
  watch: {
    //Hide ujet widget whenever payment widget is opened
    'this.$store.state.userPreferences.preferences.OUTAGE_EMAIL'(newvalue) {
    }
  },
  beforeMount(){
    if(window.location.hash === "#preferences"){
        this.toggle()
      }
  },
  mounted (){
      const element = document.getElementById("preferences")
      if(window.location.hash === "#preferences"){
        element.scrollIntoView()
      }
    },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen
    },
    savePreferences(callback) {
      const preferenceList = []
      const moblePrefDiv = document.getElementById('mobile-prefs-div')
      let obj = {}
      if (screen.width >= 1024) {
        obj =
        {
          MARKETING_NOTIFICATION: document.getElementById('marketing-notification').checked,
          MARKETING_DIRECT_MAIL: document.getElementById('marketing-direct-mail').checked,
          MARKETING_TEXT: document.getElementById('marketing-text').checked,
          MARKETING_EMAIL: document.getElementById('marketing-email').checked,
          // OUTAGE_EMAIL: document.getElementById('outage-email').checked,
          // OUTAGE_TEXT: document.getElementById('outage-text').checked,
          // OUTAGE_NOTIFICATION: document.getElementById('outage-notification').checked
        }
      }
      else{
        obj =
      {
        MARKETING_NOTIFICATION: document.getElementById('marketing-notifications-mobile').checked,
        MARKETING_DIRECT_MAIL: document.getElementById('direct-mail-mobile').checked,
        MARKETING_TEXT: document.getElementById('marketing-text-mobile').checked,
        MARKETING_EMAIL: document.getElementById('marketing-email-mobile').checked,
        // OUTAGE_EMAIL: document.getElementById('outage-email-mobile').checked,
        // OUTAGE_TEXT: document.getElementById('outage-text-mobile').checked,
        // OUTAGE_NOTIFICATION: document.getElementById('outage-notifications-mobile').checked
      }
      }
      

      //create list of object of mutation 
      Object.keys(obj).forEach((key, index) => {
        preferenceList.push(
          {
            "preferenceId": key.includes("OUTAGE") ? "OUTAGE" : "RESIDENTIAL",
            "preferenceType": key.includes("DIRECT") ? "DM" : key.includes("EMAIL") ? "EMAIL" : key.includes("NOTIFICATION") ? "PUSH" : "PHONE",
            "optIn": obj[key] ? "True" : "False"
          })
      });
      this.$store.dispatch('userPreferences/updateNotificationPreference', preferenceList)
    },
    onPreferenceChange() {
      this.hasChanges = true
    },
  },
}
</script>
<style scoped>
.check-icon{
  /* Functional Icons/Default */
  
  width: 24px;
  height: 24px;
  
  
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

</style>
