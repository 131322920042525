<template>
  <BzlModal
    v-bind="{ ...$props, ...$attrs }"
    label="Delete this payment method?"
    @sl-hide="$emit('dismiss')"
  >
    <hr class="border-gray-light lg:pt-4" />
    <div class="font-bold">Payment Method</div>
    <div class="space-y-4">
      <div>
        <AppRadioPanel
          :is-payment-method="true"
          :payment-method="paymentMethod"
          :display-checkbox="false"
          :is-selectable="false"
        ></AppRadioPanel>
      </div>
      <div
        v-if="hasIssues"
        class="relative p-4 before:absolute before:left-0 before:top-0 before:z-0 before:h-full before:w-full before:bg-salmon before:opacity-20 before:content-['']"
      >
        <div class="z-10">
          <strong> Deleting this payment method will: </strong>
          <ul class="ml-4 list-disc text-sm">
            <li v-if="isDefault">Remove it as the default payment method for this account.</li>
            <li v-if="isUsedForAutopay">
              Cancel AutoPay. Please choose a new payment method to maintain the $10 discount.
            </li>
            <li v-if="filteredScheduledPayments.length === 1">
              Cancel your scheduled payment of ${{
                filteredScheduledPayments[0].amount.toFixed(2)
              }}
              on {{ toFriendlyDate(filteredScheduledPayments[0].date) }}.
            </li>
            <li v-if="filteredScheduledPayments.length > 1">
              Cancel all scheduled payments connected to this payment method.
            </li>
          </ul>
        </div>
      </div>
      <hr class="border-gray-light lg:pt-4" />
      <div class="flex w-full flex-row justify-between">
        <BzlButton
          variant="text"
          @click="approve()"
        >
          Delete
        </BzlButton>
        <BzlButton
          variant="primary"
          @click="$emit('dismiss')"
        >
          Cancel
        </BzlButton>
      </div>
    </div>
  </BzlModal>
</template>

<script>
  export default {
    name: 'DeletePaymentMethod',
    props: {
      paymentMethod: {
        type: Object,
        required: false,
        default: null,
      },
      autopayPaymentMethod: {
        type: Object,
        required: false,
        default: null,
      },
      scheduledPayments: {
        type: Array,
        default: () => [],
      },
    },
    computed: {
      isDefault() {
        if (!this.paymentMethod) {
          return false
        }

        return this.paymentMethod.isDefault
      },
      isUsedForAutopay() {
        if (!this.autopayPaymentMethod || !this.paymentMethod) {
          return false
        }

        return this.autopayPaymentMethod.id === this.paymentMethod.id
      },
      filteredScheduledPayments() {
        return this.paymentMethod
          ? this.scheduledPayments.filter((sp) => sp.paymentMethodId === this.paymentMethod.id)
          : []
      },
      hasIssues() {
        return this.isDefault || this.isUsedForAutopay || this.filteredScheduledPayments.length > 0
      },
    },
    methods: {
      approve() {
        this.trackEvent('SPM_CONFIRM_DELETE_PAYMENT_METHOD_CLICKED')

        this.$emit('approve', this.paymentMethod, () => {
          this.$emit('dismiss')
        })
      },
    },
  }
</script>

<style scoped>
  .BzlButton[variant='text']::part(base) {
    @apply font-bold text-navy-dark;
  }
</style>
