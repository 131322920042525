import gql from 'graphql-tag'

let refetchLoop

export default {
  namespaced: true,

  state: {
    allEquipments: [],
    rebootList: [],
    equipmentHelpList: [
      {
        category: 'Equipment',
        label: 'Equipment',
        iconName: 'EquipmentIcon',
        routeName: 'equipment',
      },
      {
        category: 'Dashboard',
        label: 'Test Your Speed',
        iconName: 'SpeedIcon',
        url: 'https://www.breezeline.com/internet/test-your-speed',
        target: '_blank',
      },
      {
        category: 'Dashboard',
        label: 'Order Remote',
        iconName: 'RemoteIcon',
        url: 'https://www.breezeline.com/order-my-remote/auth',
        target: '_blank',
      },
      {
        category: 'Dashboard',
        label: 'Return Equipment',
        iconName: 'EquipmentIcon',
        url: 'https://www.breezeline.com/support/account/equipment-return',
        target: '_blank',
      },
      {
        category: 'Dashboard',
        label: 'Upgrade Modem',
        iconName: 'UpgradeModemIcon',
        url: 'https://selfcare.breezeline.com:8443/walledgarden/',
        target: '_blank',
      },
      {
        category: 'Dashboard',
        label: 'General Support',
        url: 'https://www.breezeline.com/support',
        iconName: 'SupportIcon',
        routeName: 'support',
        target: '_blank',
      },
    ],
  },

  getters: {
    getEquipmentHelp: (state) => (category) => {
      return state.equipmentHelpList.filter((i) => i.category.indexOf(category))
    },
  },

  mutations: {
    SET_EQUIPMENTS(state, payload) {
      if (payload.length > 0) {
        state.allEquipments = payload.map((eq) => ({
          make: eq.make,
          model: eq.model,
          serialNumber: eq.serialNumber,
          status: eq.status,
          typeFriendly: eq.type,
          type: 'MODEM', // temporary; only modems are being passed in, VIDEO to follow
        }))
      } else {
        state.allEquipments = []
      }
    },
    TRACK({ rebootList }, { serialNumber }) {
      if (!rebootList.includes(serialNumber)) {
        rebootList.push(serialNumber)
      }
    },
    UNTRACK({ rebootList }, { serialNumber }) {
      const index = rebootList.indexOf(serialNumber)

      if (index > -1) {
        rebootList.splice(index, 1)
      }
    },
  },

  actions: {
    initialize({ dispatch }) {
      return Promise.all([
        dispatch('_getEquipments'),
        // dispatch('_testEndpoint'),
      ])
        .then((all) => {
          // note: this applies for this whole module. if further specificity is required, we need to adjust the modulesStatus to have a deeper scope.
          dispatch('moduleIsReady', { name: 'equipments', result: true }, { root: true })
          return all
        })
        .catch((e) => {
          console.log(e)
          dispatch(
            'moduleIsReady',
            { name: 'equipments', result: { error: e } },
            { root: { error: e } }
          )
        })
    },
    _testEndpoint() {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(true)
        }, 5000)
      })
    },
    _getEquipments({ state, commit }) {
      return new Promise((resolve, reject) => {
        window.$apollo.addSmartQuery('getEquipment', {
          query: gql`
            query getEquipment {
              getEquipment {
                make
                model
                serialNumber
                status
                type
                category
              }
            }
          `,
          result: (r) => {
            if (r.data?.getEquipment) {
              commit('SET_EQUIPMENTS', r.data.getEquipment)

              // Untrack online equipments
              state.allEquipments.forEach((eq) => {
                if (eq.status) commit('UNTRACK', eq)
              })

              // Stop refetch loop once reboot list hits 0
              if (state.rebootList.length === 0) {
                clearInterval(refetchLoop)
              }

              resolve(true)
            }
          },
          error: (e) => reject(e),
        })
      })
    },
    // === PHASE 2 ===
    // _getOutage({ dispatch, commit }) {
    //      query: gql`query getOutage($accountNumber: String) {
    //          getOutage(accountNumber: $accountNumber) {
    //              affectedUserCount
    //              eta
    //              message
    //          }
    //      }`,
    //      variables: {
    //          accountNumber: '12345',
    //      },
    // },
    rebootEquipment({ commit, dispatch }, equipment) {
      commit('LOCK_APP', null, { root: true })

      // todo: bug with this mutation!
      return window.$apollo
        .mutate({
          mutation: gql`
            mutation hitDevice($serialNumber: String!, $type: EquipmentType) {
              hitDevice(serialNumber: $serialNumber, type: $type) {
                message
              }
            }
          `,
          variables: {
            serialNumber: equipment.serialNumber,
            type: equipment.type,
          },
        })
        .then((r) => {
          if (r.errors) throw r.errors

          // Add this equipment to the reboot list
          commit('TRACK', equipment)

          // (Re)start the refetch loop
          clearInterval(refetchLoop)
          refetchLoop = setInterval(() => {
            window.$apollo.queries.getEquipment.refetch()
          }, 30 * 1000)
        })
        .catch((e) => {
          console.error(e)
          return dispatch(
            'alerts/setAlert',
            {
              message: 'System error. Please try again later, or contact support.',
            },
            { root: true }
          )
        })
        .finally(() => {
          commit('UNLOCK_APP', null, { root: true })
        })
    },
  },
}
