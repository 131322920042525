<template>
  <div class="block py-4 text-sm font-bold text-navy-dark lg:hidden">
    <div style="display: none;">
      <p>Outage Notifications</p>
      <div class="my-4 flex items-center">
        <input
          id="outage-email-mobile"
          type="checkbox"
          class="mr-2"
          :checked="this.$store.state.userPreferences.preferences?.OUTAGE_EMAIL"
          @change="change()" />
        <label for="outage-email-mobile">Email</label>
      </div>
      <div class="my-4 flex items-center">
        <input
          id="outage-text-mobile"
          type="checkbox"
          class="mr-2"
          :checked="this.$store.state.userPreferences.preferences?.OUTAGE_TEXT"
          :disabled="this.$store.state.userPreferences.preferences?.OUTAGE_TEXT === undefined"
          @change="change()" />
        <label for="outage-text-mobile">Text (SMS)</label>
      </div>
      <div class="my-4 flex items-center">
        <input
          id="outage-notifications-mobile"
          type="checkbox"
          class="mr-2"
          :checked="this.$store.state.userPreferences.preferences?.OUTAGE_NOTIFICATION"
          @change="change()" />
        <label for="outage-notifications-mobile">Mobile App Push Notifications</label>
      </div>
    </div>
    <div>
      <p>Marketing Communications</p>
      <div class="my-4 flex items-center">
        <input
          id="marketing-email-mobile"
          type="checkbox"
          class="mr-2"
          :checked="this.$store.state.userPreferences.preferences?.MARKETING_EMAIL"
          @change="change()" />
        <label for="marketing-email-mobile">Email</label>
      </div>
      <div class="my-4 flex items-center">
        <input
          id="marketing-text-mobile"
          type="checkbox"
          class="mr-2"
          :checked="this.$store.state.userPreferences.preferences?.MARKETING_TEXT"
          :disabled="this.$store.state.userPreferences.preferences?.MARKETING_TEXT === undefined"
          @change="change()" />
        <label for="marketing-text-mobile">Text (SMS)</label>
      </div>
      <div class="my-4 flex items-center">
        <input
          id="direct-mail-mobile"
          type="checkbox"
          class="mr-2"
          :checked="this.$store.state.userPreferences.preferences?.MARKETING_DIRECT_MAIL"
          @change="change()" />
        <label for="direct-mail-mobile">Direct Mail</label>
      </div>
      <div class="my-4 flex items-center">
        <input
          id="marketing-notifications-mobile"
          type="checkbox"
          class="mr-2"
          :checked="this.$store.state.userPreferences.preferences?.MARKETING_NOTIFICATION"
          @change="change()" />
        <label for="marketing-notifications-mobile">Mobile App Push Notifications</label>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Mobile',
    methods: {
      change(){
        this.$emit('preference-changed')
      },
    },
  }
</script>
<style scoped>
input[type="checkbox"]:disabled{ 
  background: lightgray;
}
</style>