<template>
  <table class="my-4 hidden w-full border-collapse text-left text-sm lg:table">
    <thead>
      <tr>
        <th class="w-6/16 p-2"></th>
        <th class="w-2/16 p-2">Email</th>
        <th :class=" this.$store.state.userPreferences.preferences?.OUTAGE_TEXT === undefined ? 'gray w-2/16 p-2' : 'w-2/16 p-2'">Text (SMS)</th>
        <th class="w-2/16 p-2">Direct Mail</th>
        <th class="w-4/16 p-2">Mobile App Push Notifications</th>
      </tr>
    </thead>
    <tbody>
      <tr style="display: none;">
        <td class="p-2 font-bold">Outage Notifications</td>
        <td class="p-2">
          <input
            id="outage-email"
            type="checkbox"
            class="float-left"
            :checked="this.$store.state.userPreferences.preferences?.OUTAGE_EMAIL"
            @change="change()" />
        </td>
        <td class="p-2">
          <input
            id="outage-text"
            type="checkbox"
            class="float-left"
            :checked="this.$store.state.userPreferences.preferences?.OUTAGE_TEXT"
            :disabled="this.$store.state.userPreferences.preferences?.OUTAGE_TEXT === undefined"
            @change="change()" />
        </td>
        <td class="p-2"></td>
        <td class="p-2">
          <input
            id="outage-notification"
            type="checkbox"
            class="float-left"
            :checked="this.$store.state.userPreferences.preferences?.OUTAGE_NOTIFICATION"
            @change="change()" />
        </td>
      </tr>
      <tr>
        <td class="p-2 font-bold">Marketing Communications</td>
        <td class="p-2">
          <input
            id="marketing-email"
            type="checkbox"
            class="float-left"
            :checked="this.$store.state.userPreferences.preferences?.MARKETING_EMAIL"
            @change="change()" />
        </td>
        <td class="p-2">
          <input
            id="marketing-text"
            type="checkbox"
            class="float-left"
            :checked="this.$store.state.userPreferences.preferences?.MARKETING_TEXT"
            :disabled="this.$store.state.userPreferences.preferences?.MARKETING_TEXT === undefined"
            @change="change()" />
        </td>
        <td class="p-2">
          <input
            id="marketing-direct-mail"
            type="checkbox"
            class="float-left"
            :checked="this.$store.state.userPreferences.preferences?.MARKETING_DIRECT_MAIL"
            @change="change()" />
        </td>
        <td class="p-2">
          <input
            id="marketing-notification"
            type="checkbox"
            class="float-left"
            :checked="this.$store.state.userPreferences.preferences?.MARKETING_NOTIFICATION"
            @change="change()" />
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
  export default {
    name: 'Web',
    methods: {
      change(){
        this.$emit('preference-changed')
      }
    },
  }
</script>
<style scoped>
.gray{
  color: gray;
}
input[type="checkbox"]:disabled{ 
  background: lightgray;
}
</style>
