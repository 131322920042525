import gql from 'graphql-tag'

export default {
  namespaced: true,

  state: {
    preferences: {},
    updated: null,
  },

  getters: {
  },

  mutations: {
    SET_PREFERENCES(state, preferences) {   
      // Trasfrom data to be easily used be component

      let obj  = 
       {
        MARKETING_NOTIFICATION: true,
        MARKETING_DIRECT_MAIL: true,
        MARKETING_EMAIL: true,
        OUTAGE_EMAIL: true,
        OUTAGE_NOTIFICATION: true,
      }
      preferences.forEach(element => {
        //MARKETING
        if(element.preferenceId == "RESIDENTIAL"){
          if(element.preferenceType == "DM"){
            obj["MARKETING_DIRECT_MAIL"] = element.optIn == "True" ? true : false
          }
          else if(element.preferenceType == "EMAIL"){
            obj["MARKETING_EMAIL"] = element.optIn == "True" ? true : false
          }
          else if(element.preferenceType == "PUSH"){
            obj["MARKETING_NOTIFICATION"] = element.optIn == "True" ? true : false
          }
          else  if(element.preferenceType == "PHONE"){
            obj["MARKETING_TEXT"] = element.optIn == "True" ? true : false
          }
        }
        else{
          //OUTAGE
          if(element.preferenceType == "EMAIL"){
            obj["OUTAGE_EMAIL"] = element.optIn == "True" ? true : false
          }
          else if(element.preferenceType == "PUSH"){
            obj["OUTAGE_NOTIFICATION"] = element.optIn == "True" ? true : false
          }
          else if(element.preferenceType == "PHONE"){
            obj["OUTAGE_TEXT"] = element.optIn == "True" ? true : false
          }
        }
      });
      
      state.preferences = obj

    },
    SET_UPDATED(state, result) {   
      state.updated =  result

    },
    UPDATE_PREFERENCE(state, { preferenceId, optIn }) {
      const preference = state.preferences.find((p) => p.preference_id === preferenceId)
      if (preference) {
        preference.opt_in = optIn
      }
    },
  },

  actions: {
    initialize({ dispatch }) {
      return Promise.all([dispatch('_getUserPreferences')])
        .then((all) => {
          dispatch('moduleIsReady', { name: 'userPreferences', result: true }, { root: true })
          return all
        })
        .catch((e) => {
          console.error(e)
          dispatch(
            'moduleIsReady',
            { name: 'userPreferences', result: { error: e } },
            { root: { error: e } }
          )
        })
    },
    _getUserPreferences({ commit }) {
      return new Promise((resolve, reject) => {
        window.$apollo.addSmartQuery('getNotificationPreference', {
          query: gql`
            query notificationPreference {
              getNotificationPreference {
              preferenceId
              optIn
              preferenceType
              }
            }
          `,
          result: (r) =>
            r.data?.getNotificationPreference &&
            resolve(commit('SET_PREFERENCES', r.data.getNotificationPreference)),
          error: (e) => reject(e),
        })
      }).catch((e) => {
        //
        throw e?.message?.includes('server error')
          ? 'communication server error'
          : `communication error: ${e}`
      })
    },

    setUserPreference({ commit }, { preferenceId, optIn }) {
      commit('SET_PREFERENCES', { preferenceId, optIn })
    },

    updateNotificationPreference({ commit, dispatch }, preferenceList) {
      return window.$apollo
        .mutate({
          mutation: gql`
            mutation updateNotificationPreference($preferenceList: [NotificationPreferenceInput]) {
              updateNotificationPreference(preferenceList: $preferenceList) {
                message
              }
            }
          `,
          variables: {
            preferenceList: preferenceList,
          },
        })
        .then((r) => {
          if (r.errors) throw r.errors
          console.log( r.data.updateNotificationPreference?.message)
          commit('SET_UPDATED', !r.data.updateNotificationPreference?.message ? true : false)
          // Note: this is not the same as re-dispatching _getUserPreferences()
          // return window.$apollo.queries.getNotificationPreference.refetch()
        })
        .catch((e) => {
          console.error(e)

          return dispatch(
            'alerts/setAlert',
            {
              message: 'System error. Please try again later, or contact support.',
            },
            { root: true }
          )
        })
    },
  },
}
