<template>
  <div v-if="frnData.length">
    <AppPanel
      variant="alternate"
      :collapsible="true"
      label="Broadband Facts"
      :notifications="frnData.length"
    >
      <div v-if="frnData.length > 1" class="tabs inline-flex border">
        <div
          v-for="(item, index) in frnData"
          :key="'tab-' + (index + 1)"
          class="tab-item text-center"
          :class="{ 'bg-blue text-white': tab === index }"
          @click="tab = index"
        >
          Plan {{ index + 1 }}:
        </div>
      </div>
      <p>
        Below is the fixed broadband consumer disclosure presented to you at the time of purchase.
      </p>

      <div v-for="(item, index) in frnData" :key="'plan-' + (index + 1)" class="tab-panel">
        <!-- Apply transition wrapper for fade effect -->
        <transition name="fade">
          <div v-if="tab === index" :id="'plan-' + (index + 1)" v-html="item.html"></div>
        </transition>
      </div>
    </AppPanel>
  </div>
  <AppPanel
    v-else
    variant="alternate"
    :collapsible="true"
    label="Broadband Facts"
  >
    <div>
      Our records indicate that your last purchase of broadband services from Breezeline was made
      before April 10, 2024. Therefore, no Broadband Facts label is available. If you believe this
      is an error, please contact us at 888.536.9600.
    </div>
  </AppPanel>
</template>

<script>
  export default {
    name: 'AccountLabels',
    data: () => ({
      divHTML: null,
      frnData: [],
      tab: 0,
    }),
    computed: {},
    mounted() {
      this.fetchFrnData();
    },
    methods: {
      fetchFrnData() {
        const broadbandLabels = this.$store.state.broadbandLabels.broadbandLabels;

        const frns = [];
        for ( const key in broadbandLabels) {
          if (broadbandLabels[key]?.fccLabel && broadbandLabels[key].fccLabel.frn) {
            frns.push(broadbandLabels[key].fccLabel.frn);
          }
        }

        if (frns.length > 0) {
          frns.forEach((frn) => {
            fetch(`${import.meta.env.VITE_API_URL}/api/broadband-labels/frn?frn=${frn}`)
              .then((res) => {
                if (!res.ok) {
                  throw new Error('Failed to fetch data');
                }
                return res.text();
              })
              .then((html) => {
                this.frnData.push({ frn, html });
              })
              .catch((err) => {
                window.Sentry.captureException(`Failed to fetch FRN ${frn}: `, err);
              });
          });
        }
      },
    },
  }
</script>

<style>
/* How to hit the various label elements*/
:root {
  --h2-font-size: 16px;
  --h2-font-weight: 700;
  --h2-line-height: 21.79px;
  --navy-color: #001E62;
  --small-font-size: 12px;
  --small-font-weight: 400;
  --small-line-height: 16.34px;
  --subtext-font-size: 14px;
  --subtext-font-weight: 400;
  --subtext-line-height: 19.07px;

}

body {
  .additional-charges li,
  .discounts-and-bundles div,
  .speeds li,
  .included-data div,
  .customer-support div,
  .footer,
  .plan-id {
    font-size: var(--subtext-font-size);
    font-weight: var(--subtext-font-weight);
    line-height: var(--subtext-line-height);

  }

  .wrapper-label {
    max-width: 100%;
  }

  .network-management,
  .additional-charges {
    padding-bottom: 24px;
  }

  .header span,
  .monthly-price span {
    font-size: var(--small-font-size);
    font-weight: var(--small-font-weight);
    line-height: var(--small-line-height);
    color: var(--navy-color);
  }

  .additional-charges h3 em {
    font-size: 15px;
  }

  .top-label {
    border-bottom: 5px solid black;
  }

  .top-label h1 {
    font-size: 36px;
    line-height: 1.1;
  }

  .label {
    color: var(--navy-color) !important;
    text-align: left !important;
    width: 100%;
    padding: 0;
    max-width: 900px;

    h1 {
      font-size: 32px;
      font-weight: 700;
      line-height: 43.58px;
    }

    h2,
    h3,
    .monthly-price label {
      font-size: var(--h2-font-size);
      font-weight: var(--h2-font-weight);
      line-height: var(--h2-line-height);

    }

    .header h3 {
      margin-left: 0;
    }

  }
}

  .tabs {
    padding: 2px;
    border-radius: 40px;
  }

  .tab-item {
    width: 125px;
    padding: 15px;
    font-weight: 700;
    border-radius: 40px;

    &:hover {
      cursor: pointer;
    }
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.3s ease;
  }

  .fade-enter, .fade-leave-to {
    opacity: 0;
  }

/* Left align labels when it hits max width */
@media screen and (min-width: 480px) {
  body .label {
    margin: 50px 0;
  }
}

@media screen and (max-width: 480px) {
  body .label{
    .additional-charges,
    .included-data,
    .network-management{
      padding-bottom: 20px;
    }
  }
}
</style>
